import { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonCard,
  IonImg,
  IonCardContent,
  IonText,
  IonButton,
  IonCol,
} from "@ionic/react";

import "./YappaBusiness.css";
import { BtnsLikeShare } from "../botonesLikeShare/BtnsLikeShare";
import { BtnCanjearYappa } from "../botonCanjear/BTNCanjearYappa";
export const YappaBusiness = () => {
  const slug = useParams();
  const [yappa, setYappa] = useState<any[]>([]);

  useEffect(() => {
    const getYappaInfo = async (id: any) => {
      const response = await fetch(
        "https://mi.yappa.ec/api/yappas_del_negocio",
        {
          method: "POST",
          body: JSON.stringify({ id_negocio: id.id }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await response.json();

      setYappa(data.original);
    };

    getYappaInfo(slug);
  }, [slug]);

  return (
    <IonList>
      <IonItem>
        <IonGrid>
          <IonRow>
            {yappa.map((value) => {
              return (
                <IonCard
                  class="cardNegocio"
                  key={value.id}
                  href={`/yappa/${value.id}`}
                  color="primary"
                >
                  <IonImg class="imCard" src={value.url} />
                  <IonCardContent>
                    <IonText class="titelCenter">
                      <h2>
                        <b>{value.nombre}</b>
                      </h2>
                    </IonText>
                    <IonText class="titelCenter">
                      <p>{value.descripcion}</p>
                    </IonText>
                    <BtnsLikeShare />
                    <IonText>
                      <b>Yappas disponibles: </b>
                      <b>{value.cantidad < 0 ? 'Agotada' : value.cantidad}</b>
                    </IonText>
                    <br />
                    <IonText>
                      <b>Valido desde: </b>
                      <b>{value.fecha_publicacion}</b>
                    </IonText>
                    <br />
                    <IonText>
                      <b>hasta: </b>
                      <b>{value.fecha_expiracion}</b>
                    </IonText>
                    <IonRow class="btnsFooter">
                      <IonCol>
                        <IonButton
                          shape="round"
                          color="tertiary"
                          expand="block"
                        >
                          Conoce más
                        </IonButton>
                      </IonCol>
                      <IonCol>
                        <BtnCanjearYappa obj={yappa[0]} />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              );
            })}
          </IonRow>
        </IonGrid>
      </IonItem>
    </IonList>
  );
};
