import { Route } from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonRouterOutlet,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Theme variables */
import "./theme/variables.css";

/* Importando Vistas de la App */
import Inicio from "./vistas/inicio/Inicio";
import { Business } from "./vistas/negocioConYappa/Business";
import { Yappa } from "./vistas/yappa/Yappa";
import { MisYappas } from "./vistas/misYappas/MisYappas";
import { Configuracion } from "./vistas/configuraciones/Configuracion";
import { YappasCalientes } from "./vistas/yappasCalientes/YappasCalientes";
import { Registro } from "./vistas/registro/RegistroUser";
import { Login } from "./vistas/login/Login";
import { TerminosCondiciones } from "./vistas/terminosCondiciones/TerminosCondiciones";
import { Valoracion } from "./vistas/valoracionYappa/Valoracion";
/* Importando componentes Globales*/
import { TodosLosNegocios } from "./componentes/listaTodosLosNegocios/ListaTodosLosNegocios";
import { YappaCategoria } from "./vistas/yappaCategoria/YappaCategoria";
import { Historial } from "./vistas/historial/Historial";
import { recoverPass } from "./vistas/recuperacionUsuario/RecuperacionPass";
import { MiPerfil } from "./vistas/miPerfil/MiPerfil";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import Qr from "./vistas/QR/Qr";

import "./app.css";

setupIonicReact({
  mode: "md",
});

const App: React.FC = () => (
  <IonApp>
    <IonContent>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/inicio" component={Inicio}></Route>
          <Route exact path="/" component={Inicio}></Route>
          <Route exact path="/misyappas" component={MisYappas}></Route>
          <Route exact path="/historial/" component={Historial}></Route>
          <Route
            exact
            path="/yappasCalientes"
            component={YappasCalientes}
          ></Route>
          <Route exact path="/perfil" component={MiPerfil}></Route>
          <Route exact path="/configuracion" component={Configuracion}></Route>
          <Route exact path="/business" component={TodosLosNegocios}></Route>
          <Route exact path="/business/:id" component={Business}></Route>
          <Route exact path="/yappa/:categoria/:id" component={Yappa}></Route>
          <Route exact path="/historial/:id" component={Historial}></Route>
          <Route
            exact
            path="/yappa_categoria/:id"
            component={YappaCategoria}
          ></Route>
          <Route exact path="/registroUser/" component={Registro}></Route>
          <Route
            exact
            path="/recuperacionContrasena/"
            component={recoverPass}
          ></Route>
          <Route exact path="/login" component={Login}></Route>
          <Route
            exact
            path="/TerminosCondiciones"
            component={TerminosCondiciones}
          ></Route>
          <Route exact path="/Valoracion" component={Valoracion}></Route>
          <Route exact path="/qr/:id" component={Qr}></Route>

          <Route
            exact
            path="/yappa_business/:id_negocio"
            component={Business}
          ></Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonContent>
  </IonApp>
);

export default App;
