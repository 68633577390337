/* eslint-disable jsx-a11y/alt-text */
import { useIonAlert, useIonLoading } from "@ionic/react";
// eslint-disable-next-line no-unused-vars
import { useHistory } from "react-router-dom";
import RedireccionarLogin from "../../funcs/RedireccionarLogin";

import "./btnCanjear.css";

export const BtnCanjearYappa = (props) => {
  const yappa = { ...props.obj };
  const { whatsapp } = props;
  const [presentAlert] = useIonAlert();
  const [present] = useIonLoading();
  const history = useHistory();

  const validarSesion = () => {
    return sessionStorage.getItem("id") != null &&
      sessionStorage.getItem("id") !== "null"
      ? true
      : false;
  };

  const canjear = async () => {
    if (validarSesion()) {
      // eslint-disable-next-line no-restricted-globals
      const id_yappa = location.href.split("/");

      const body = {
        id_usuario: +sessionStorage.getItem("id"),
        id_yappa: +id_yappa.at(-1) || yappa.id,
      };

      const response = await fetch("https://mi.yappa.ec/api/canjear_yappa", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      });

      await response.json();

      present({
        message: "Canjeando Yappa",
        duration: 2000,
        cssClass: "MsgOk",
        translucent: true,
        showBackdrop: true,
      });

      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        window.open(whatsapp, "_blank");
      }, 2000);
    } else {
      RedireccionarLogin(
        presentAlert,
        history,
        "Recuerda que para usar tu yappa debes tener una cuenta."
      );
    }
  };

  return (
    <p>
      <button
        className="btnQuieroYappa"
        onClick={() => {
          canjear();
        }}
      >
        <h3>Quiero mi yappa</h3>
        <img
          className="imgBtnCanjearYappa"
          src="https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/189794e5-5209-40a6-9fbb-3b4d97feee00/public"
          alt="icon"
        />
      </button>
      {/* </span> */}
    </p>
  );
};
