const RedireccionarLogin = (presentAlert, history, message) => {
  presentAlert({
    header: message,
    cssClass: "MsgError",
    buttons: [
      {
        text: "Ingresar",
        handler: () => {
          history.push("/login");
        },
      },
      {
        text: "Crear cuenta",
        handler: () => {
          history.push("/registroUser");
        },
      },
    ],
  });
};

export default RedireccionarLogin;
