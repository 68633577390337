/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonRow,
  IonTitle,
} from "@ionic/react";
import React from "react";
import { BtnsLikeShare } from "../botonesLikeShare/BtnsLikeShare";
import "./resultadoBuscador.css";

import { BtnVerYappa } from "../btnVerYappa/BtnVerYappa";


export const ResultadoBuscador = (props: any) => {
  const { id, url, nombre, negocio, fecha_expiracion, categoria, cantidad } =
    props;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
      <section>
        <IonCard
          className="colorCard"
          onClick={() => {
            let path = sessionStorage.getItem("path");
            path !== ""
              ? (path += `,${location.href}`)
              : (path += `${location.href}`);
            sessionStorage.setItem("path", `${path}`);
            location.href = `yappa/${categoria.replaceAll(" / ", "-")}/${nombre.replaceAll(" ", "-")}`;
          }}
        >
          <img className="imgCard" src={url}></img>
          <IonCardContent>
            <div className="tituloandbtnBusqueda">
              <div className="infoYappanego">
                <h6 className="nombreCategoria">{categoria}</h6>
                <h4 className="nombreEmpresa">{negocio}</h4>
                <h2 className="tituloBusqueda">{nombre}</h2>
              </div>
              <div className="botonesLike">
                <BtnsLikeShare id_yappa={id} />
              </div>
            </div>
            <div className="textosDetalles">
              <p>Yappas disponibles</p>
            </div>
            <div className="textosDetallesBusqueda2">
              <div className="cantidadYappas">
                <h4>{cantidad}</h4>
              </div>
              <div className="detallefecha">
                <h4 className="infoFecha">
                </h4>
              </div>
            </div>

            <div className="btnVerYappa">
              <BtnVerYappa
                obj={nombre.replaceAll(" ", "-")}
                category={categoria.replaceAll(" / ", "-")}
              />
            </div>
          </IonCardContent>
        </IonCard>
      </section>
  );
};
