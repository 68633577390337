import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
} from "@ionic/react";
import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "@ionic/react/css/ionic-swiper.css";
import { Pagination } from 'swiper/modules';


import "./terminosCondiciones.css";
import MenuOver from "../../componentes/header/MenuOver";
import { BtnBack } from "../../componentes/btnBack/BtnBack";

export const TerminosCondiciones: React.FC = () => {
  return (
    <>
      <MenuOver />
      <IonPage id="main-content">
        <IonHeader>
          <section className="headerNavBarA">
            <div className="cssBack">
              <BtnBack />
            </div>
            <div>
              <a href={"/inicio"}>
                <img
                  className="logoMenuYappa"
                  alt="logo yappa"
                  src={
                    "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/9fc0ed25-63ad-4365-d2e4-6739bc7f2c00/public"
                  }
                />
              </a>
            </div>
            <div className="cssBottonMenu">
              <IonMenuButton></IonMenuButton>
            </div>
          </section>
        </IonHeader>

        <IonHeader>
          <IonTitle>
            {" "}
            <h2 className="titulos">Terminos y Condiciones de Uso </h2>
          </IonTitle>
        </IonHeader>
        <IonContent>
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            loop={true}
          >
            <SwiperSlide>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle></IonCardTitle>
                  <IonCardSubtitle>
                    <h4>INFORMACIÓN RELEVANTE</h4>
                  </IonCardSubtitle>
                  <IonCardContent>
                    <p>
                      Bienvenido(a) a nuestra aplicación móvil y plataforma web
                      (en adelante, "la aplicación"). Antes de utilizarla, por
                      favor, lee detenidamente los siguientes términos y
                      condiciones. Al acceder y utilizar la aplicación, aceptas
                      estar legalmente vinculado(a) por estos términos y
                      condiciones, así como por nuestra Política de Uso de
                      Datos. Si no estás de acuerdo con alguno de estos
                      términos, te recomendamos que no utilices la aplicación.
                    </p>
                    <p>1. Uso de la Aplicación:</p>
                    <ul>
                      <li>
                        a. La aplicación está destinada únicamente para uso
                        personal y no comercial. No puedes utilizarla para
                        ningún propósito ilegal, no autorizado o que infrinja
                        los derechos de terceros.
                      </li>
                      <li>
                        b. Al utilizar la aplicación, aceptas cumplir con todas
                        las leyes, reglamentos y normativas aplicables.
                      </li>
                    </ul>
                    <p>2. Compras:</p>
                    <ul>
                      <li>
                        a. La aplicación permite realizar compras de productos o
                        servicios. Al realizar una compra, aceptas que eres
                        responsable de proporcionar información precisa y
                        completa, incluyendo datos de pago válidos.
                      </li>
                      <li>
                        b. Nos reservamos el derecho de rechazar cualquier
                        solicitud de compra por cualquier motivo, en cualquier
                        momento.
                      </li>
                    </ul>
                    <p>3. Propiedad Intelectual:</p>
                    <ul>
                      <li>
                        a. La aplicación y todo su contenido (incluyendo, pero
                        no limitado a, texto, gráficos, logotipos, imágenes,
                        audio, video, software y cualquier otra forma de
                        contenido) están protegidos por derechos de autor,
                        marcas registradas y otras leyes de propiedad
                        intelectual.
                      </li>
                      <li>
                        b. No se permite copiar, distribuir, modificar,
                        transmitir, mostrar, realizar, reproducir, publicar,
                        otorgar licencias, crear trabajos derivados o vender
                        cualquier contenido obtenido de la aplicación sin
                        nuestro consentimiento previo por escrito.
                      </li>
                    </ul>
                    <p>4. Limitación de Responsabilidad:</p>
                    <ul>
                      <li>
                        a. La aplicación se proporciona "tal cual" y no
                        ofrecemos garantías de ningún tipo, ya sean explícitas o
                        implícitas, incluyendo, pero no limitado a, garantías de
                        comerciabilidad, idoneidad para un propósito particular
                        y no infracción.
                      </li>
                      <li>
                        b. No seremos responsables de ningún daño directo,
                        indirecto, incidental, especial o consecuente que surja
                        del uso de la aplicación o de cualquier compra realizada
                        a través de la misma.
                      </li>
                    </ul>
                    <p>5. Modificaciones:</p>
                    <p>
                      Nos reservamos el derecho de modificar o actualizar estos
                      términos y condiciones en cualquier momento. Te
                      recomendamos revisar periódicamente esta sección para
                      estar informado(a) de cualquier cambio.
                    </p>
                    <p>6. Ley Aplicable:</p>
                    <p>
                      Estos términos y condiciones se regirán e interpretarán de
                      acuerdo con las leyes del país de origen de la aplicación.
                    </p>
                    Política de Uso de Datos:
                    <p>1. Recopilación de Datos:</p>
                    <ul>
                      <li>
                        a. Al utilizar la aplicación, podemos recopilar y
                        almacenar información personal, como nombres,
                        direcciones de correo electrónico, ubicación GPS e
                        identificadores de dispositivo.
                      </li>
                      <li>
                        b. También podemos recopilar información no personal,
                        como direcciones IP y datos de navegación, con el fin de
                        mejorar la funcionalidad y seguridad de la aplicación.
                      </li>
                    </ul>
                    <p>2. Uso de los Datos:</p>
                    <ul>
                      <li>
                        a. Utilizamos la información recopilada para
                        proporcionar y mejorar los servicios de la aplicación,
                        procesar compras, personalizar la experiencia del
                        usuario, responder a consultas y enviar información
                        relevante.
                      </li>
                      <li>
                        b. No compartiremos tus datos personales con terceros no
                        autorizados, a menos que sea necesario para cumplir con
                        las leyes aplicables, proteger nuestros derechos legales
                        o en caso de una fusión o adquisición de la empresa.
                      </li>
                    </ul>
                  </IonCardContent>
                </IonCardHeader>
              </IonCard>
            </SwiperSlide>
            <SwiperSlide>
              <IonCard>
                <IonCardSubtitle>
                  <h4>LICENCIA</h4>
                </IonCardSubtitle>
                <IonCardContent>
                  <p>
                    TBO Soluciones Digitales a través de su sitio web concede
                    una licencia para que los usuarios utilicen los productos
                    que son vendidos en este sitio web de acuerdo a los Términos
                    y Condiciones que se describen en este documento.
                  </p>
                </IonCardContent>
              </IonCard>
            </SwiperSlide>
            <SwiperSlide>
              <IonCard>
                <IonCardSubtitle>
                  <h4>USO NO AUTORIZADO</h4>
                </IonCardSubtitle>
                <IonCardContent>
                  <p>
                    En caso de que aplique (para venta de software, templetes, u
                    otro producto de diseño y programación) usted no puede
                    colocar uno de nuestros productos, modificado o sin
                    modificar, en un CD, sitio web o ningún otro medio y
                    ofrecerlos para la redistribución o la reventa de ningún
                    tipo.
                  </p>
                </IonCardContent>
              </IonCard>
            </SwiperSlide>
          </Swiper>
        </IonContent>
      </IonPage>
    </>
  );
};
