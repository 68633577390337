/* eslint-disable no-restricted-globals */
import "./yappasRelacionadas.css";

import { IonCard, IonCardContent } from "@ionic/react";

const YappasRelacionadas = (props: any) => {
  const { yappa, empresa, categoria, url } = props;

  return (
    <section className="yappasRelacionadasContainer">
      <IonCard className="fondoCardRelacionada">
        <img
          className="imgCardelacinada"
          src={url}
          alt=""
          onClick={() => {
            let path = sessionStorage.getItem("path");
            path !== ""
              ? (path += `,${location.href}`)
              : (path += `${location.href}`);
            sessionStorage.setItem("path", `${path}`);

            let url = "";

            if (yappa.search(/Viajes/) >= 0) {
              url = yappa.replaceAll(" / ", "-");
            }else{
              url = yappa.replaceAll(" ", "-");
            }

            location.href = `yappa/${categoria.replaceAll(" / ", "-")}/${url}`;
          }}
        />

        <IonCardContent>
          <section className="informacionRelacionada">
            <div className="nombreYappaRela">
              <div>
                <h2>{yappa}</h2>
              </div>
            </div>
            <div className="detallesRelacionada">
              <div>
                <h6> {categoria} </h6>
              </div>
              <div>
                <h4> {empresa} </h4>
              </div>
            </div>
          </section>
          <div className="botonVer">
            <button
              className="btnVerYappa"
              onClick={() => {
                let path = sessionStorage.getItem("path");
                path !== ""
                  ? (path += `,${location.href}`)
                  : (path += `${location.href}`);
                sessionStorage.setItem("path", `${path}`);

                let url = "";

                if (yappa.search(/Viajes/) >= 0) {
                  url = yappa.replaceAll(" / ", "-");
                }else{
                  url = yappa.replaceAll(" ", "-");
                }

                location.href = `yappa/${categoria.replaceAll(" / ", "-")}/${url}`;
              }}
            >
              <h3> Ver Yappa </h3>
              <img
                className="imgBtnveryappa"
                src="https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/189794e5-5209-40a6-9fbb-3b4d97feee00/public"
                alt="icon"
              />
            </button>
          </div>
        </IonCardContent>
      </IonCard>
    </section>
  );
};
export default YappasRelacionadas;
