import { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonCard,
  IonText,
  IonImg,
  IonCardContent,
  IonIcon,
} from "@ionic/react";
import "./Business.css";

export const Business = () => {
  const slug = useParams();
  const [business, setBusiness] = useState<any[]>([]);
  const [stars] = useState<any[]>([4]);

  useEffect(() => {
    const getBusiness = async (id: any) => {
      const response = await fetch(
        "https://mi.yappa.ec/api/informacion_negocio",
        {
          method: "POST",
          body: JSON.stringify(id),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await response.json();

      setBusiness(data);
    };

    getBusiness(slug);
  }, [slug]);

  return (
    <IonList>
      <IonItem>
        <IonGrid>
          <IonRow>
            {business.map((value, index) => {
              return index === 0 ? (
                <IonCard class="carContent" key={index} color={"secondary"}>
                  <IonImg class="imgContent" src={value.url_imagen} />
                  <IonCardContent>
                    <IonRow class="ion-justify-content-center">
                      <IonText>
                        <h4>
                          <b>{value.nombre}</b>
                        </h4>
                      </IonText>
                    </IonRow>

                    <IonText>
                      <h4>
                        <b>{value.descripcion}</b>
                      </h4>
                    </IonText>
                    <IonText>
                      <IonCard color="secondary">
                        <IonCardContent class="points">
                          <IonText class="left">
                            <b>Popularidad: </b>
                            <b>Yappas: </b>
                          </IonText>
                          {stars.map((value) => {
                            return (
                              <IonText class="right">
                                <span>
                                  <b>
                                    {value}{" "}
                                    <IonIcon name="star-outline"></IonIcon>
                                    <div className="progress_bar">
                                      <div className="progress_bar_status"></div>
                                    </div>
                                  </b>
                                </span>
                                <span>
                                  <b>(120)</b>
                                </span>
                              </IonText>
                            );
                          })}
                        </IonCardContent>
                      </IonCard>
                    </IonText>
                    <IonRow class="ion-justify-content-center">
                      <IonText class="limit-date">
                        <span>
                          <b>Horario de atención: </b>
                          <b>{value.horario}</b>
                        </span>
                      </IonText>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              ) : (
                false
              );
            })}
          </IonRow>
        </IonGrid>
      </IonItem>
    </IonList>
  );
};
