const AddToPath = (redirect) => {
  let path = sessionStorage.getItem("path");

  // eslint-disable-next-line no-restricted-globals
  path !== "" ? (path += `,${location.href}`) : (path += `${location.href}`);
  sessionStorage.setItem("path", `${path}`);

  // eslint-disable-next-line no-restricted-globals
  location.href = redirect;
};

export default AddToPath;