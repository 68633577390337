import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInfiniteScroll,
  IonItem,
} from "@ionic/react";
// import { Category } from "../../componentes/categorias/Category";
import { Business as Negocio } from "../../componentes/negocios/Business";
import { YappaBusiness } from "../../componentes/negociosConYappa/YappaBusiness";

export const Business = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Informacion del Negocio</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent >
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Informacion del Negocio</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <IonItem>
          <IonInfiniteScroll>
            <Negocio />
            <YappaBusiness />
          </IonInfiniteScroll>
        </IonItem>
      </IonContent>
      {/* <Category /> */}
    </IonPage>
  );
};
