import { useState, useEffect } from "react";
import {
    IonContent,
    IonList,
    IonItem,
    IonGrid,
    IonRow,
    IonCard,
    IonText,
    IonImg,
    IonCardContent,
    IonIcon,
} from "@ionic/react";


export const TodosLosNegocios = () => {
    const [business, setBusiness] = useState<any[]>([]);

    useEffect(() => {
        const getBusiness = async () => {
            const response = await fetch(
                "https://mi.yappa.ec/api/negocios_con_yappa"
            );
            const data = await response.json();
            setBusiness(data);
        };

        getBusiness();
    }, []);

    return (
        <IonContent>
            <IonList>

                {business.map((value) => {
                    return <IonItem key={value.id}>
                        <IonGrid>
                            <IonRow>
                                <IonCard href={`/business/${value.id}`}
                                    style={{
                                        borderBottomRightRadius: 20,
                                        borderTopRightRadius: 20,
                                        borderBottomLeftRadius: 20,
                                        borderTopLeftRadius: 20
                                    }}
                                    color="secondary"
                                >
                                    <IonImg
                                        src={value.url_imagen}
                                    ></IonImg>
                                    <IonCardContent>
                                        <IonRow class="ion-justify-content-end">
                                            <IonText>
                                                <span>
                                                    <b style={{ textAlign: "right" }}>
                                                        <IonIcon name="star-outline"></IonIcon>
                                                        <IonIcon name="star-outline"></IonIcon>
                                                        <IonIcon name="star-outline"></IonIcon>
                                                        <IonIcon name="star-outline"></IonIcon>
                                                        <IonIcon name="star-outline"></IonIcon>
                                                    </b>
                                                    <b style={{ padding: "3px" }}>5.0</b>
                                                </span>
                                            </IonText>
                                        </IonRow>


                                    </IonCardContent>
                                </IonCard>
                            </IonRow>
                        </IonGrid>
                    </IonItem>

                })}
            </IonList>
        </IonContent>
    );
};
