
import { IonInput, IonPage } from "@ionic/react";

import('./recuperacionPass.css');

export const recoverPass = () => {
  return (
    <>

      <IonPage class="fondoRecoverPass">
        <container class="containerRecoveryPass">

          <section>
            <div>
              <h2>
                Recuperación de contraseña
              </h2>
            </div>
          </section>
          <section>
            <div>
              <p>
                <b>
                  Al parecer olvidaste tu contraseña, indicanos tu correo electrónico, y te enviaremos un enlace
                  para que puedas crear una nueva.
                </b>
              </p>
            </div>
          </section>
          <section>
            <div className='divMailBTN'>
              <IonInput class='inputMailReg'
                placeholder=' Ingresa tu email'
                type="email"

              />
            </div>
          </section>
          <section>
            <div className='divMailBTN'>
              <IonInput class='inputMailReg'
                placeholder=' Ingresa tu codigo'
                type="text"

              />
            </div>
          </section>

          <section>
            <div className='divMailBTN'>
              
              <IonInput class='inputMailReg'
                placeholder=' Ingresa tu nueva contraseńa'
                type="text"
              />
            </div>
            <div className='divMailBTN'>
              <IonInput class='inputMailReg'
                placeholder=' Repite tu nueva contraseńa'
                type="text"
              />
            </div>
          </section>



          <section>
            <div className='divContBTN'>
              <button className='btnRegGoogle'>
                <p>
                  Solicitar restablecimiento de contraseńa
                </p>
              </button>

            </div>
          </section>

        </container>



      </IonPage>
    </>
  );
}