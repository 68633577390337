import { type ReactNode } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type SlidersProps = {
  variableWidth: boolean;
  slidesToScroll: number;
  infinite: boolean;
  children: ReactNode;
}

const Sliders = ({variableWidth, slidesToScroll, infinite, children}:SlidersProps) => {

  const settings = {
    arrows: false,
    dots: true,
    infinite: infinite,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: slidesToScroll,
    variableWidth: variableWidth,
    adaptativeHeight: true
  };

  return (

    <Slider {...settings}>
      {children}
    </Slider>
  )
}

export default Sliders;