const ScreenSize = (state) => {
  // eslint-disable-next-line no-restricted-globals
  if (screen.width > 1900) {
    state(4);
    // eslint-disable-next-line no-restricted-globals
  } else if (screen.width > 1251 && screen.width < 1900) {
    state(3);
    // eslint-disable-next-line no-restricted-globals
  } else if (screen.width > 720 && screen.width < 1250) {
    state(2);
    // eslint-disable-next-line no-restricted-globals
  } else if (screen.width > 300 && screen.width < 720) {
    state(1);
  }
};

export default ScreenSize;
