/* eslint-disable no-restricted-globals */
import { type ReactElement, type FormEvent, useState, useEffect } from "react";
import classes from "./BuscadorGlobal.module.css";
import { IonSearchbar } from "@ionic/react";
import { ResultadoBuscador } from "../resultadoBuscador/ResultadoBuscador";
import "@ionic/react/css/ionic-swiper.css";
import "swiper/css/pagination";

import ScreenSize from "../../funcs/ScreenSize";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

type Yappas = {
  id: number;
  url: string;
  nombre: string;
  ciudad: string;
  negocio: string;
  fecha_expiracion: string;
  subcategoria: string;
  descripción: string;
  cantidad: string;
};

export const BuscadorGlobal = () => {
  const [mensaje, setMensaje] = useState<string | ReactElement>("");
  // eslint-disable-next-line
  const [numCateg, setnumCateg] = useState<number>(0);
  const [yappasBuscadas, setYappasBuscadas] = useState<Yappas[]>([]);

  useEffect(() => {
    ScreenSize(setnumCateg);
  }, []);

  const mensaje_yappas = (mensaje: string | ReactElement) => {
    setMensaje(<div className={classes.resulNo}>{mensaje}</div>);

    setTimeout(() => {
      setMensaje("");
    }, 5000);
  };

  const buscar = async (palabra: string) => {
    setYappasBuscadas([]);

    const body = {
      texto: palabra,
    };

    if (palabra.trim() !== "") {
      const response = await fetch("https://mi.yappa.ec/api/yappa_busqueda", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });

      const data = await response.json();
      let array = [];

      (await data.original.length) <= 0
        ? mensaje_yappas("No hay Yappas disponibles para tu búsqueda")
        : (array = await data.original);

      return array;
    } else {
      setMensaje("");
      return [];
    }
  };

  const buscarYappasControlador = async (
    event: FormEvent<HTMLIonSearchbarElement>
  ) => {
    setYappasBuscadas(await buscar(event.currentTarget.value!));
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      <IonSearchbar
        className={classes.search}
        onKeyUp={(event) => {
          buscarYappasControlador(event);
        }}
        type="search"
        placeholder="¡Encuentra tu YAPPA!"
        showClearButton="never"
      ></IonSearchbar>
      <div className={classes.seccionSwiper}>
        {yappasBuscadas.length > 0 ? (
          <Carousel responsive={responsive} containerClass="">
            {yappasBuscadas.map((value: Yappas, index: number) => {
              return (
                <ResultadoBuscador
                  key={index}
                  id={value.id}
                  url={value.url}
                  nombre={value.nombre}
                  ciudad={value.ciudad}
                  negocio={value.negocio}
                  fecha={value.fecha_expiracion}
                  categoria={value.subcategoria}
                  descripcion={value.descripción}
                  cantidad={value.cantidad}
                />
              );
            })}
          </Carousel>
        ) : (
          <>{mensaje}</>
        )}
      </div>
    </>
  );
};
