/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";

import Sliders from "../Sliders/Sliders";
import classes from "./sliderPautas.module.css";
import banners from "../../funcs/BannerGuidelines";
import AddToPath from "../../funcs/AddToPath";

type Pautas = {
  id: number;
  url: string;
}

const SliderPautas: React.FC = () => {
  const [pautas, setPautas] = useState<Pautas[]>([]);

  const traer_yappas_pautadas = async () => {
    const response = await fetch("https://mi.yappa.ec/api/yappa_pautada");
    const data = await response.json();

    if (screen.width > 600) {
      setPautas(banners);
    } else {
      setPautas(banners);
    }

    if ((await data.original.length) > 0) {
      data.original.unshift({
        id: 0,
        url: "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/d10c92f4-21b3-42bf-e817-2196f196f300/public",
      });
      setPautas(data.original);
    }
  };

  useEffect(() => {
    traer_yappas_pautadas();
  }, []);

  return (
    <>
      <Sliders variableWidth={false} slidesToScroll={1} infinite={true}>
        {pautas.map((value, index) => {
          return (
            <div
              key={index}
            >
              <section className={classes.sectionBanner}>
                <img className={classes.imgPau}
                  src={value.url}
                  alt={`Banner ${index}`}
                  onClick={() => {
                    if (value.id > 0) AddToPath(`yappa/${value.id}`);
                  }}
                />
              </section>
            </div>
          );
        })}
      </Sliders>
    </>
  );
};
export default SliderPautas;
